import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/ui/Layout";
import GridItem from "../../components/ui/GridItem/GridItem";
import ProjectList from "../../components/ProjectList/ProjectList";
import { getImage } from "gatsby-plugin-image";

const Projects = ({ data }) => {
  return (
    <Layout title="Projects">
      <ProjectList>
        {data.allMdx.nodes.map((node) => {
          const image = getImage(node.frontmatter.image_main);

          return (
            <GridItem
              key={node.id}
              title={node.frontmatter.title}
              link={`/projects/${node.slug}`}
              date={node.frontmatter.date}
              image={image}
              alt={node.frontmatter.image_alt}
            />
          );
        })}
      </ProjectList>
    </Layout>
  );
};

export const query = graphql`
  {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
          image_alt
          image_main {
            childImageSharp {
              id
              gatsbyImageData
            }
          }
        }
        id
        slug
      }
    }
    imageSharp {
      id
    }
  }
`;

export default Projects;
