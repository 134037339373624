import React from 'react'
import * as classes from './ProjectList.module.css'

const ProjectList = ({ children }) => {
  return (
    <section className={classes.projectList}>
      <div className={classes.intro}>
        <h1 className="header-decoration">My Projects</h1>
        <p>
          Some projects, websites and web apps I developed. I've included links
          to Github repositories where possible as well as links to the projects
          themselves.
        </p>
      </div>
      <div className={classes.grid}>{children}</div>
    </section>
  )
}

export default ProjectList
