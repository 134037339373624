import React from "react";
import * as classes from "./GridItem.module.css";
import { ExternalLink, Calendar } from "react-feather";
import ButtonLink from "../ButtonLink";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const GridItem = (props) => {

  return (
    <div className={classes.gridItem}>
      <Link to={props.link}>
        <GatsbyImage image={props.image} alt={props.alt} />
        <h2 className={classes.title}>{props.title}</h2>
      </Link>
      <p className={classes.date}><Calendar />{props.date}</p>
      <ButtonLink to={props.link}>Read More</ButtonLink>
      <Link className={classes.externalLink} to={props.link}>
        <ExternalLink />
      </Link>
    </div>
  );
};

export default GridItem;
